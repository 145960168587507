import React from 'react';
import { BsFillEmojiFrownFill, BsFillEmojiNeutralFill } from 'react-icons/bs';
import { Button, Row } from '@atomic';
import { TextareaFeedback } from './emotion-today.style';
export var JustificativaEmocaoNegativa = function (_a) {
    var selectedEmotion = _a.selectedEmotion, setMotivoRespostaNegativa = _a.setMotivoRespostaNegativa, sendRequest = _a.sendRequest;
    var submitJustificativa = function () {
        sendRequest();
    };
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
        React.createElement(Row, { style: { justifyContent: 'center' } }, selectedEmotion === 'Triste' ? (React.createElement(React.Fragment, null,
            React.createElement(BsFillEmojiFrownFill, { color: "red", size: 80 }))) : (React.createElement(React.Fragment, null,
            React.createElement(BsFillEmojiNeutralFill, { color: "orange", size: 80 })))),
        React.createElement("h3", null, "Sabendo disto, o que voc\u00EA pode fazer para mudar esta situa\u00E7\u00E3o?"),
        React.createElement(TextareaFeedback, { placeholder: "Escreva aqui seu feedback...", onChange: function (e) { return setMotivoRespostaNegativa(e.currentTarget.value); }, style: { height: '150px' } }),
        React.createElement(Button, { onClick: function () { return submitJustificativa(); }, style: { width: '-webkit-fill-available', borderRadius: '15px', textAlign: 'center' } }, "ENVIAR RESPOSTA")));
};
