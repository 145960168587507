import React from 'react';
import { BsFillEmojiFrownFill, BsFillEmojiLaughingFill, BsFillEmojiNeutralFill } from 'react-icons/bs';
import { Button } from '@atomic';
import coins from '../../../../assets/img/coinv2.png';
import { EmotionButtons, EmotionalInfo, FeedbackSession, TextareaFeedback } from './emotion-today.style';
export var TermometroEmocional = function (_a) {
    var selectedEmotion = _a.selectedEmotion, setSelectedEmotion = _a.setSelectedEmotion, setFeedbackText = _a.setFeedbackText, setIsOpenJustificativaEmocaoNegativa = _a.setIsOpenJustificativaEmocaoNegativa, userData = _a.userData, setTermometroEmocionalPreenchido = _a.setTermometroEmocionalPreenchido, sendRequest = _a.sendRequest;
    var handleEmotion = function (e) {
        setSelectedEmotion(e.target.value);
    };
    var onSendClick = function () {
        setTermometroEmocionalPreenchido(true);
        if (selectedEmotion === 'Triste' || selectedEmotion === 'Indiferente') {
            setIsOpenJustificativaEmocaoNegativa(true);
        }
        else {
            setIsOpenJustificativaEmocaoNegativa(false);
            sendRequest();
        }
    };
    return (React.createElement("div", null,
        React.createElement("h2", null, "TERM\u00D4METRO EMOCIONAL"),
        React.createElement(EmotionalInfo, null,
            React.createElement("div", { className: "info" },
                React.createElement("div", { className: "coin" },
                    React.createElement("span", { style: { fontFamily: 'UniNeueRegular' } }, "Ganhe +30"),
                    React.createElement("span", { style: { marginBottom: '8px' } },
                        React.createElement("img", { src: coins, alt: "moeda", style: { width: '25px', height: '25px' } }))))),
        React.createElement("h3", null, "Como voc\u00EA est\u00E1 hoje?"),
        React.createElement(EmotionButtons, { disabled: true },
            React.createElement("label", { className: "sad " + (selectedEmotion === 'Triste' ? 'selectedButton' : '') },
                React.createElement("input", { type: "radio", name: "emotion", value: "Triste", onChange: handleEmotion }),
                React.createElement(BsFillEmojiFrownFill, { color: "red", size: 80 })),
            React.createElement("label", { className: "indif " + (selectedEmotion === 'Indiferente' ? 'selectedButton' : '') },
                React.createElement("input", { type: "radio", name: "emotion", value: "Indiferente", onChange: handleEmotion }),
                React.createElement(BsFillEmojiNeutralFill, { color: "orange", size: 80 })),
            React.createElement("label", { className: "happy " + (selectedEmotion === 'Feliz' ? 'selectedButton' : '') },
                React.createElement("input", { type: "radio", name: "emotion", value: "Feliz", onChange: handleEmotion }),
                React.createElement(BsFillEmojiLaughingFill, { color: "green", size: 80 }))),
        React.createElement(FeedbackSession, null,
            React.createElement("div", null,
                React.createElement(TextareaFeedback, { placeholder: "Gostaria de compartilhar algo conosco?", onChange: function (e) { return setFeedbackText(e.currentTarget.value); } })),
            React.createElement("div", null,
                React.createElement(Button, { disabled: !selectedEmotion, onClick: onSendClick, style: { width: '-webkit-fill-available', borderRadius: '15px', textAlign: 'center' } }, "ENVIAR")))));
};
