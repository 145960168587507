import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useStore as useStoreRCH } from 'react-context-hook';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { useUserStore } from '@app/providers';
import { ModalThermometer } from '@atomic/obj.modal-thermometer';
import { JustificativaEmocaoNegativa } from './justificativa-emocao-negativa';
import { TermometroEmocional } from './termometro-emocional';
export var EmotionToday = function () {
    var BASE_TERMOMETRO_EMOC_ENDPOINT = '/termometro-emocional';
    var showFlashMessage = useFlashMessage()[0];
    var _a = React.useState(false), termometroEmocionalPreenchido = _a[0], setTermometroEmocionalPreenchido = _a[1];
    var _b = React.useState(''), selectedEmotion = _b[0], setSelectedEmotion = _b[1];
    var _c = React.useState(''), feedbackText = _c[0], setFeedbackText = _c[1];
    var _d = React.useState(''), motivoRespostaNegativa = _d[0], setMotivoRespostaNegativa = _d[1];
    var userData = useStore(useUserStore).userData;
    var _e = React.useState(false), isOpenJustificativaEmocaoNegativa = _e[0], setIsOpenJustificativaEmocaoNegativa = _e[1];
    var _f = useStoreRCH('afterReplyTermometroEmocionalFunction'), afterReplyTermometroEmocionalFunction = _f[0], setAfterReplyTermometroEmocionalFunction = _f[1];
    var _g = useStoreRCH('isUserAlreadyRepliedTermometroEmocionalToday'), _ = _g[0], setIsUserAlreadyRepliedTermometroEmocionalToday = _g[1];
    var handleOnClosePopUp = function () {
        if (termometroEmocionalPreenchido) {
            sendRequest();
        }
        else {
            registerEmotionTodayReplyOnLS();
            setAfterReplyTermometroEmocionalFunction(undefined);
        }
    };
    var sendRequest = function () {
        registerTermometro();
        axiosClient
            .post(BASE_TERMOMETRO_EMOC_ENDPOINT, { emotion: selectedEmotion, motivo: feedbackText, motivoRespostaNegativa: motivoRespostaNegativa })
            .then(function () {
            setAfterReplyTermometroEmocionalFunction(undefined);
        })
            .catch(function (error) {
            var _a, _b;
            setAfterReplyTermometroEmocionalFunction(undefined);
            showFlashMessage('alert', ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) || 'Erro ao enviar feedback');
        });
    };
    var registerEmotionTodayReplyOnLS = function () {
        var currentDate = format(new Date(), 'uuuu-M-dd');
        localStorage.setItem('ent-termometro-emocional', "term-emoc-" + userData.usuarioId + "-" + currentDate);
    };
    var hasEmotionTodayLSCookie = function () {
        var currentDate = format(new Date(), 'uuuu-M-dd');
        var answeredToken = localStorage.getItem('ent-termometro-emocional');
        return answeredToken === "term-emoc-" + userData.usuarioId + "-" + currentDate;
    };
    var updateAlreadyRepliedAppStatus = function () {
        setIsUserAlreadyRepliedTermometroEmocionalToday(true);
        setAfterReplyTermometroEmocionalFunction(undefined);
        if (!hasEmotionTodayLSCookie()) {
            registerEmotionTodayReplyOnLS();
        }
    };
    var registerTermometro = function () {
        registerEmotionTodayReplyOnLS();
        setIsUserAlreadyRepliedTermometroEmocionalToday(true);
        afterReplyTermometroEmocionalFunction();
        setAfterReplyTermometroEmocionalFunction(undefined);
    };
    useEffect(function () {
        if (hasEmotionTodayLSCookie()) {
            updateAlreadyRepliedAppStatus();
        }
        else {
            axiosClient.get(BASE_TERMOMETRO_EMOC_ENDPOINT).then(function (_a) {
                var isUserAnsweredResponse = _a.data;
                if (isUserAnsweredResponse.data) {
                    updateAlreadyRepliedAppStatus();
                }
                else {
                    setIsUserAlreadyRepliedTermometroEmocionalToday(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, userData.usuarioId, !!afterReplyTermometroEmocionalFunction]);
    return (React.createElement(ModalThermometer, { opened: !!afterReplyTermometroEmocionalFunction, closeOnOutsideClick: false, small: true, onClose: handleOnClosePopUp }, isOpenJustificativaEmocaoNegativa ? (React.createElement(JustificativaEmocaoNegativa, { selectedEmotion: selectedEmotion, setMotivoRespostaNegativa: setMotivoRespostaNegativa, sendRequest: sendRequest })) : (React.createElement(TermometroEmocional, { selectedEmotion: selectedEmotion, setFeedbackText: setFeedbackText, setSelectedEmotion: setSelectedEmotion, setIsOpenJustificativaEmocaoNegativa: setIsOpenJustificativaEmocaoNegativa, userData: userData, setTermometroEmocionalPreenchido: setTermometroEmocionalPreenchido, sendRequest: sendRequest }))));
};
